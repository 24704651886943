<template>
	<div class="">
		<a-spin :spinning="loading">
			<div class="flex space alcenter">
				<div class="ft20 cl-main ftw600">{{getTitle}}</div>
				<a-button @click="$router.go(-1)">返回</a-button>
			</div>
			
			<div class="mt20 bg-w pd40">
				<div style="width: 1000px;">
					<a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
						<a-form-model-item required label="商品名称" help="30字以内">
							<a-input v-model="form.name" placeholder="输入商品名称" style="width: 480px;"></a-input>
						</a-form-model-item>
						
						<a-form-model-item required label="商品编码" >
							<a-input v-model="form.code" placeholder="输入商品编码" style="width: 480px;"></a-input>
						</a-form-model-item>
						
						<a-form-model-item required label="商品分类">
							<a-tree-select v-model="form.category_id" style="width: 200px"
								:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" :tree-data="category"
								placeholder="请选择" >
							</a-tree-select>
						</a-form-model-item>
						
						<a-form-model-item label="排序">
							<a-input-number v-model="form.sort"   placeholder="请输入" :precision="0" :min="0" style="width: 120px;"/>
						</a-form-model-item>
						
						<a-form-model-item required label="门店原价">
							<a-input-number v-model="form.original_price"  :formatter="value => `${value}元`" placeholder="请输入" :precision="2" :min="0" style="width: 120px;"/>
						</a-form-model-item>
						
						<a-form-model-item required label="会员价格">
							<a-input-number v-model="form.vip_price"  :formatter="value => `${value}元`" placeholder="请输入" :precision="2" :min="0" style="width: 120px;"/>
						</a-form-model-item>
						
						<a-form-model-item label="商品库存">
							<a-input-number v-model="form.quota" placeholder="请输入" :precision="0" :min="0" style="width: 120px;"/>
						</a-form-model-item>
						
						<a-form-model-item label="展示已售的数量">
							<a-input-number v-model="form.fictitious_take_count" placeholder="请输入" :precision="0" :min="0" style="width: 120px;"/>
						</a-form-model-item>
						
						<a-form-model-item required label="是否首推">
							<a-radio-group v-model="form.is_tui">
								<a-radio :value="1">是</a-radio>
								<a-radio :value="0">否</a-radio>
							</a-radio-group>
						</a-form-model-item>

            <a-form-model-item required label="上下架">
              <a-radio-group v-model="form.status">
                <a-radio :value="1">上架</a-radio>
                <a-radio :value="0">下架</a-radio>
              </a-radio-group>
            </a-form-model-item>
						
						<a-form-model-item required label="开启预售">
							 <div class="flex alcenter">
								 <a-switch v-model="form.is_open_yu==1" @change="changePreSale"/>
								 <div class="ml10">下单后需过段时间才能发货</div>
							 </div>
							 
							 <div class="mt10" v-if="form.is_open_yu==1">
								 <div class="flex alcenter">
									 <div class="ft14 ftw500 cl-info">预售价格</div>
									 <div class="ml16">
										 <a-input-number v-model="form.yu_price" :formatter="value => `${value}元`" placeholder="请输入" :precision="2" :min="0" style="width: 120px;"/>
									 </div>
								 </div>
								 <div class="flex alcenter">
									 <div class="ft14 ftw500 cl-info">发货时间</div>
									 <div class="ml16">
										<div class="flex alcenter">
											 <div class="ft14 ftw500 cl-info">付款成功</div>
											 <div class="ml16">
												 <a-input-number v-model="form.yu_days"  placeholder="请输入天数" :precision="0" :min="0" style="width: 120px;"/>
											 </div>
											 <div class="ft14 ftw500 cl-info ml16">天后发货</div>
										</div>
									 </div>
								 </div>
							 </div>
						</a-form-model-item>
						
						
						
						<a-form-model-item required label="商品规格">
							<div class="flex alcenter">
								<a-radio-group v-model="form.is_have_sku">
									<a-radio :value="1">有</a-radio>
									<a-radio :value="0">无</a-radio>
								</a-radio-group>
								 <div class="ml10 cl-red">开启规格后库存就是规格里库存的总和</div>
							</div>
							
							
							<div class="mt10" v-if="form.is_have_sku==1">
								<table class="wxb-table list" style="width: 100%;">
									<thead>
										<th>规格名称</th>
										<th>门店原价</th>
										<th>会员价格</th>
										<th v-if="form.is_open_yu==1">预售价格</th>
										<th>库存</th>
										<th>操作</th>
									</thead>
									<tbody>
										<tr v-for="(item,index) in form.sku">
											<td>
												<a-input  placeholder="请输入" v-model="item.name"></a-input>
											</td>
											<td>
												<a-input-number v-model="item.original_price" :formatter="value => `${value}元`" placeholder="请输入" :precision="2" :min="0" />
											</td>
											<td>
												<a-input-number v-model="item.vip_price" :formatter="value => `${value}元`" placeholder="请输入" :precision="2" :min="0" />
											</td>
											<td v-if="form.is_open_yu==1">
												<a-input-number v-model="item.yu_price" :formatter="value => `${value}元`" placeholder="请输入" :precision="2" :min="0" />
											</td>
											<td>
												<a-input-number v-model="item.quota"  placeholder="请输入" :precision="0" :min="0" />
											</td>
											<td>
												<div class="clickAct" @click="delSkuItem(index)">
													<i class="iconfont iconbtn_close ft20 cl-notice "/>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
								<div class="">
									<a-button type="primary" icon="plus" ghost @click="addSkuItem()">添加</a-button>
								</div>
							</div>
						</a-form-model-item>
						
						<a-form-model-item required label="缩略图" >
							<div class="ft12 ftw400 cl-notice">建议上传240x240px</div>
							<div class="mt10 ml20">
								<upload-img v-model="form.cover_img"></upload-img>
							</div>
						</a-form-model-item>
						
						<a-form-model-item required label="详情页轮播图" >
							<div class="ft12 ftw400 cl-notice">最多可传5张，建议上传750x750px</div>
							<div class="mt10 ml20">
								<upload-list v-model="form.rotation"></upload-list>
							</div>
						</a-form-model-item>
						
						<div class="edit-goods-line mb40"></div>
						
						<a-form-model-item label="商品详情" required class="mt20">
							<html-edit v-model="form.detail"></html-edit>
						</a-form-model-item>
						
						<div class="edit-goods-line"></div>
						
						<a-form-model-item label="购买须知">
							<div class="ft12 ftw400 cl-notice">不填就显示默认内容，可修改</div>
							<a-textarea  v-model="form.purchase_information" :rows="11" />
						</a-form-model-item>
						
						
						<a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
						      <a-button type="primary" @click="onSubmit" :loading="confirmLoading">
						        确定保存
						      </a-button>
						</a-form-model-item>
					</a-form-model>	
					
				</div>
			</div>
		</a-spin>
	</div>
</template>

<script>
	import uploadImg from '../../components/upload/upload.vue';
	import uploadList from '../../components/upload/list.vue';
	import  htmlEdit from '../../components/html.vue';
	export default{
		components:{
			uploadImg,
			uploadList,
			htmlEdit
		},
		data(){
			return {
				loading:false,
				confirmLoading:false,
				labelCol: { span: 4 },
			    wrapperCol: { span: 20 },
				goods_id:0,
				category: [],
				form: {
					name:'',
					code:'',
					category_id:undefined,
					sort:'',
					original_price:'',
					vip_price:'',
					quota:'',
					fictitious_take_count:'',
					is_tui:0,
          status:1,
					is_open_yu:0,
					yu_price:'',
					yu_days:'',
					is_have_sku:0,
					sku:[],
					cover_img:'',
					rotation:[],
					detail:'',
					purchase_information:'',
				}
			}
		},
		created() {
			if (!this.$route.query.id) {
				this.goods_id=0;
			} else {
				this.goods_id = parseInt(this.$route.query.id);
			}
			this.loaddata();
		},
		computed:{
			getTitle(){
				if(this.goods_id==0){
					return '添加商品';
				}else{
					return '编辑商品';
				}
			}
		},
		methods:{
			loaddata(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/showGoods',{
					goods_id:this.goods_id
				}).then(res=>{
					this.loading=false;
					this.category=res.category;
					if(res.detail){
						this.form=res.detail;
					}
				}).catch(res=>{
					console.log(res);
					this.loading=false;
					this.$router.go(-1);
				})
			},
			onSubmit() {
				if(this.confirmLoading==true) return;
				this.confirmLoading=true;
				this.$http.api('admin/saveGoods',{
					goods_id:this.goods_id,
					name: this.form.name,
					code:this.form.code,
					category_id: this.form.category_id,
					sort:this.form.sort,
					original_price:this.form.original_price,
					vip_price: this.form.vip_price,
					quota:this.form.quota,
					fictitious_take_count:this.form.fictitious_take_count,
					is_tui: this.form.is_tui,
          status: this.form.status,
					is_open_yu:this.form.is_open_yu,
					yu_price: this.form.yu_price,
					yu_days: this.form.yu_days,
					is_have_sku: this.form.is_have_sku,
					sku: JSON.stringify(this.form.sku),
					cover_img: this.form.cover_img,
					rotation: this.form.rotation,
					detail:this.form.detail,
					purchase_information:this.form.purchase_information,
				}).then(res=>{
					this.$message.success('保存成功');
					this.confirmLoading=false;
					this.$router.go(-1);
				}).catch(res=>{
					console.log(res);
					this.confirmLoading=false;
				})
			},
			
		
			addSkuItem(){
				this.form.sku.push({
					name:'',
					original_price:'',
					vip_price:'',
					yu_price:'',
					quota:'',
				})
			},
			
			delSkuItem(index){
				this.form.sku.splice(index,1);
			},
			
			changePreSale(){
				this.form.is_open_yu = this.form.is_open_yu == 1 ? 0 : 1;
			}
			
		}
	}
</script>

<style>
	.edit-goods-line{
		width: 100%;
		height: 1px;
		background: #EBEDF5;
	}
	
	.edit-goods-details-text {
		width: 120px;
		height: 40px;
		border-radius: 4px;
		border: 1px solid #5333FF;
	}
	
	.mobile-content{
		width: 750px;
	}
	.mobile-content{
		
	}
	
	.mobile-item{
		position: relative;
	}
	.mobile-item .action{
		position: absolute;
		left: 0;
		top: -15px;
		width: 100%;
		z-index: 2;
	}
	.del-action{
		width: 30px;
		height: 30px;
		background: rgba(0,0,0,.5);
		text-align: center;
		line-height: 30px;
		border-radius: 15px;
		color:#FFFFFF;
		position: absolute;
		right: -15px;
		top:-15px;
		z-index: 3;
	}
	.up-action,.down-action{
		width: 30px;
		height: 30px;
		background: #FFFFFF;
		text-align: center;
		line-height: 30px;
		border-radius: 15px;
		color:#007AFF;
		box-shadow:0px 2px 8px 0px rgba(0,0,0,0.08);
	
	}
</style>